import React from 'react'
import { Link } from 'react-router-dom';

const Back = ({location, company, project}) => {
    return (
        <div class="back-to">
	<Link class="back" to={'/' + location}><span>Back to <br/>{location}</span></Link>
	<div>
		<h4>{company}</h4>
		<h3>{project}</h3>
	</div>
</div>
    )
}

export default Back
