import Home from './Home/index';
import About from './About/index';
import Work from './Work/index';
import Lab from './Lab/index';
import Contact from './Contact/index';
import Adeo from './Work/projects/Adeo';
import Aevo from './Work/projects/Aevo';
import Clark from './Work/projects/Clark';
import DDSharkWeek from './Work/projects/DDSharkWeek';
import Edge from './Work/projects/Edge';
import Inspirl from './Work/projects/Inspirl';
import RedTSNY from './Work/projects/RedTSNY';
import Rise from './Work/projects/Rise';
import Tsasigna from './Work/projects/Tsasigna';
import TVNext from './Work/projects/TVNext';
import AllAboutYou from './Lab/projects/AllAboutYou';
import DDDriveThru from './Lab/projects/DDDriveThru';
import iBeacon from './Lab/projects/iBeacon';
import OculusRiff from './Lab/projects/OculusRiff';
import Pur from './Lab/projects/Pur';
import TweetShuffle from './Lab/projects/TweetShuffle';
const appRoutes = [
    {
        path: "/",
        navbarName: "Home",
        component: Home,
        exact: true,
    },
    {
        path: "/about",
        navbarName: "About",
        component: About
    },
    {
        path: "/work",
        navbarName: "Work",
        component: Work,
        exact: true

    },
    {
        path: "/work/adeo",
        navbarName: "Website",
        logo: "",
        component: Adeo,
        hidden:true
    },
    {
        path: "/work/aevo",
        navbarName: "Website",
        logo: "",
        component: Aevo,
        hidden: true
    },
    {
        path: "/work/clark",
        navbarName: "Website",
        logo: "",
        component: Clark,
        hidden: true
    },
    {
        path: "/work/ddsharkweek",
        navbarName: "Website",
        logo: "",
        component: DDSharkWeek,
        hidden: true
    },
    {
        path: "/work/edge",
        navbarName: "Website",
        logo: "",
        component: Edge,
        hidden: true
    },
    {
        path: "/work/inspirl",
        navbarName: "Website",
        logo: "",
        component: Inspirl,
        hidden: true
    },
    {
        path: "/work/red-tsnyc",
        navbarName: "Website",
        logo: "",
        component: RedTSNY,
        hidden: true
    },
    {
        path: "/work/rise",
        navbarName: "Website",
        logo: "",
        component: Rise,
        hidden: true
    },
    {
        path: "/work/tasigna",
        navbarName: "Website",
        logo: "",
        component: Tsasigna,
        hidden: true
    },
    {
        path: "/work/tvnext",
        navbarName: "Website",
        logo: "",
        component: TVNext,
        hidden: true
    },
    {
        path: "/lab",
        navbarName: "Lab",
        component: Lab,
        exact:true
    },
    {
        path: "/lab/all-about-you",
        navbarName: "Lab",
        component: AllAboutYou,
        hidden: true
    },
    {
        path: "/lab/dd-drivethru",
        navbarName: "Lab",
        component: DDDriveThru,
        hidden: true
    },
    {
        path: "/lab/ibeacon",
        navbarName: "Lab",
        component: iBeacon,
        hidden: true
    },
    {
        path: "/lab/oculusriff",
        navbarName: "Lab",
        component: OculusRiff,
        hidden: true
    },
    {
        path: "/lab/pur",
        navbarName: "Lab",
        component: Pur,
        hidden: true
    },
    {
        path: "/lab/tweet-shuffle",
        navbarName: "Lab",
        component: TweetShuffle,
        hidden: true
    },
    {
        path: "/contact",
        navbarName: "Contact",
        component: Contact
    }
];
export default appRoutes;
