import React from 'react'
import Back from './../../components/Back';
import './tasigna.scss'
const Tsasigna = () => {
    return (
        <div>
            <Back back company="Novardis" project="Tasigna" location="work"/>
            <div className="wd-hero" style={{backgroundImage: `url('/images/tasigna/tasigna-hero.jpg')`}}>
                <div className="outer-container">

                </div>
            </div>

            <main className="work-detail">

                <section>
                    <div className="block one-col">
                        <h1>
                            <span>Novardis</span>
                            Tasigna
				<span>Integrated Social Campaign / Website</span>
                        </h1>
                        <div className="two-col-text">
                            <p>Chronic myeloid leukemia is a rare form of blood cancer. So when patients are diagnosed with CML, they can feel really isolated. That’s why Tasigna needed to create a community—create a team. And luckily we had one of the best team players ever—the NBA’s all-time highest scorer—Kareem Abdul-Jabbar.</p>
                            <p>So we created “What’s my best shot?” This comprehensive patient experience starts with Kareem’s journey, from his legendary basketball career to his CML diagnosis and beyond to all the milestones he’s accomplished since. Then we invited all patients to join the team by creating their own journeys and taking their own best shot at treating CML.</p>

                        </div>
                    </div>
                </section>
                <section className="video">
                    <h2>View Case Study</h2>
                    <div className="pattern"></div>
                    <div className="block one-col">
                        <div fit-vids className="fit-vids">
                            <iframe title="tsasigna"src="//player.vimeo.com/video/120988897" width="1200" height="675" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="block two-col">
                        <section>

                            <h2>Technology</h2>
                            <ul className="technology-list">
                                <li>Responsive Design</li>
                                <li>Facebook API</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                            </ul>
                        </section>

                    </div>
                </section>

            </main>
        </div>
    )
}

export default Tsasigna
