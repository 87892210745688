import React from 'react'
import './about.scss'
import { MeshHead } from './../components/MeshHead';
const About = () => {
    return (
    <main class="about">
        <div ng-include="'components/navbar/navbar.html'"></div>
        <div class="about profile"></div>
            <div class="callout">
                <MeshHead color1="#000000" color2="#cb2829" color3="#ffffff"/>
                <h1>About</h1>
            </div>
            <section class="about-me">
                <div class="block one-col">
                    <p>I'm Jeff Boulay, a web designer, developer, speaker,  musician, and father located near Boston, MA. Currently how I pay the bills is as a Senior Creative Technoligist at Hill Holliday.</p>

                    <p>I’ve been very active in the web development community speaking and co-organizing the Boston HTML5 Meetup growing the group from a handful to over twelve hundred members. I am passionate about web technologies and creating rich experiences for all devices.</p>
                </div>
            </section>
            <section class="history">
                <div class="block one-col">
                    <h2>Work History</h2>
                    <ul>
                        <li class="wow fadeInUp">
                            <h3>Senior Creative Technologist</h3>
                            <h4>Hill Holliday, Boston, MA	May 2012 – Current</h4>
                            <p>Work collaboratively with interdepartmental teams to create compelling cross-medium integrated campaigns with national and international brands. Perform a highly cross-functional roll of developer, designer, and user experience expert. Assume tech lead rolls on a number of projects with companies such as Dunkin’ Donuts, LG, Chili’s, Cadillac, Necco Candy, Cigna, Liberty Mutual, VH1, Samaritans, Novartis, creating award-winning work. Develop responsive web sites and applications integrating with Facebook, Twitter, Instagram, Google maps and YouTube. Contribute to user experience and design for Honda, VH1, Capella University. Work closely with creative directors, copywriters on ideation, prototyping, pitch, and technical direction. Created compelling real-time data visualizations with D3, Google Maps, Web Sockets. Lead the front-end team successfully training and managing junior staff and tutoring senior staff on best practices in javascript architecture, GIT workflow, responsive design, mobile strategy, and out of home digital solutions. Initiated internal labs group to work on projects to grow the knowledge and share the skill sets of the innovation and technology team.</p>
                        </li>
                        <li class="wow fadeInUp">
                            <h3>Director of Interactive Design – 1year Contract</h3>
                            <h4>Neoscape, Boston, MA	May 2011 – May 2012</h4>
                            <p>Responsible for producing professional, polished, user-centered, designs on a wide array of interactive platforms including, desktop, tablet, mobile, large screen touch and non-touch based displays. Established and maintain multiple clients brand strategy and identity. Led creative brainstorming sessions to create holistic design solutions that melded, both the client’s business goals and brand their identity delivering best in class interactive experiences for our clients audience. Worked both Independently and with a larger interactive and user experience teams bringing strategic ideas and designs to the table. Brought new design concepts, trends, and technology to the group working establish design guidelines to create responsive designs that can bring a wider level of market penetration to our clients while leveraging existing content and web presence. Managed projects directly with clients from concept to completion. Also provided the company with advice on sound recording and sound designs for television commercials and brand experience video presentation. Neoscape is a full service interactive agency that delivers best in class 3D, rich immersive videos, and compelling interactive experiences for the high-end commercial architecture and real estate vertical market.</p>
                        </li>
                        <li class="wow fadeInUp">
                            <h3>Lead Web Designer, Front-end Developer</h3>
                            <h4>FirstGiving, Boston, MA	September 2010 – May 2011</h4>
                            <p>Successfully drove the design and monitoring of two large-scale marketing campaigns - one of which generated over $1 million in donations. Rebranded and reinvigorated FirstGiving’s website and international donation platform.  Worked with both the core Boston team and the San Francisco labs team in creating a consistent unified branded experience. Led FirstGiving’s design and front-end development efforts, worked directly with the executive, marketing, product and development teams. Worked with the development team to redesign every aspect of the social donation platform from the ground up using technologies such as MVC.net, jQuery, Git, SVN, HTML5, CSS3 and compass. We were able to optimize the users experience and deliver a compelling, engaging product. Worked with in an agile work environment with tight deadlines and lofty goals. As part of the product team, I translated quantitative data and user testing research into decisions that created user-centered designs.</p>
                        </li>
                        <li class="wow fadeInUp">
                            <h3>Web Designer, Front-end Developer</h3>
                            <h4>WinZip Computing a Corel Company, Mansfield, CT	January 2007 – September 2010</h4>
                            <p>Grew the company’s web site revenue by 15% year over year.  Managed the design, branding, and marketing of Corel owned WinZip compression utility. Focused on delivering measurable results in design, usability, and marketing of WinZip’s international website which maintains full SOX compliance and localized versions in 9 languages.  Closely involved in all creative decisions made for their website, mass mailing and 3rd party promotional materials. Made key decisions on the user interaction and helped craft user experience within the WinZip software as well. Measurably improved user’s interaction / conversion with key target area’s within the website, newsletter, public trial reminders and keyword landing pages. Worked with third party partners and vendors to increase company revenue. Integrated and managed the Omniture implementation and continued maintenance.</p>
                        </li>
                        <li class="wow fadeInUp">
                            <h3>Senior Designer, Front-end Developer</h3>
                            <h4>Omega Communication Inc. Southington, CT	July 2002 – January 2007</h4>
                            <p>Worked on projects from concept to completion for both medium and large-scale clients adding over $60,000 new annual revenue in new business that I personally managed.  Strong communication and interpersonal skills with the ability to up-sell additional features and benefits to customers that increased average billing by 10%.  Managed a dynamic team of designers and developers in creating websites, ecommerce solutions, detailed documentation, user interfaces, website wireframes, and flash based multimedia presentations. Partnered with our sales and customer marketing teams to outline and develop critical criteria for high profile clients.  Worked directly with clients of Fortune 100 and publicly traded companies including Becton Dickinson, N.V. Perricone M.D., Chase Medical Research, MRI of New Britain, Spaskin, Penwest and others.</p>
                        </li>
                        <li class="wow fadeInUp">
                            <h3>Content Developer</h3>
                            <h4>Dynamic Minds Inc. Stamford, CT	April 2001 – Jan 2002</h4>
                            <p>An essential part of the Dynamic Minds creative development team. Clients included Prentice Hall, Pall Corporation, and others. Worked under strict deadline working on multiple projects, while maintaining quality and content integrity. Held a diverse role in Flash creations and conceptualization, interactive multimedia CD’s, video animation and content creation, sound manipulation, editing, and CD creation. Also played a vital role in the development of product and content management, including audio storyboard template and audio specifications contract.</p>
                        </li>
                        <li class="wow fadeInUp">
                            <h3>Web designer, Head of Flash Integration</h3>
                            <h4>JL Interactive Inc., Bridgeport, CT	May 2000 - April 2001</h4>
                            <p>A core member of the JL Interactive web development team, clients have included eMarketWorld, Pentax, AdTech, and others. Broad based responsibilities included creative conceptualization, web layout design, Flash layout design, computer illustration, scanning, photo manipulation, banner ads, teaching flash and other web based applications to the staff. A dynamic contributor in creative sessions, generating ideas and fleshing out concepts. Championed new media exploration and integration.</p>

                        </li>
                    </ul>
                    <div class="wow fadeInUp">
                        <h2>Education</h2>
                        <ul>
                            <li>Attended South by Southwest 2014</li>
                            <li>Attended E4E Developer Conference 2013</li>
                            <li>Attended MobileWebDevCon 2013</li>
                            <li>Attended 4A’s Annual Createtech Summit 2013</li>
                            <li>Attended FTIC Web Unleashed 2013</li>
                            <li>Attended 4A’s Annual Createtech Summit 2012</li>
                            <li>Attended Adobe MAX 2011 pre-conference and conference</li>
                            <li>Lynda.com Online Education Logged over 200 hours in online education, 2007 – current</li>
                            <li>RIA Unleashed, Boston, MA November 2009</li>
                            <li>ASP.NET Development Tour, New York, NY February 2004</li>
                            <li>Macromedia MX Studio Tour, Hartford, CT November 2002</li>
                            <li>University of New Haven, West Haven, CT September 1997-May 2001</li>
                            <li>Bachelor of Arts: Music and Sound Recording/Emphasis in Multimedia</li>
                            <li>Southington High School, Southington, CT September 1993 - June 1997</li>

                        </ul>
                    </div>
                    <div class="wow fadeInUp">
                        <h2>Skills</h2>
                        <ul>
                            <li><strong>Development</strong>: AngularJS, BackboneJS, D3JS, Require, Grunt, Yeoman, Node, HTML5, Canvas, CSS3, Javascript, jQuery, Ajax/XHR, Compass/SASS, Actionscript 3, Web Standards, Browser Compatibility, Email Compatibility, MVC.net, PHP (Code Igniter), Python (Django/Tornado), Git, SVN, JSON, Terminal, Bugzilla, Jira, Basecamp, Joomla, WordPress, Drupal, Expression Engine, Laravel, Vagrant</li>
                            <li><strong>Design</strong>: Photoshop, Illustrator, Sketch, Fireworks, Flash</li>
                            <li><strong>Presenter</strong>: HTML5 video, responsive design, Jquery Mobile, rapid prototyping tips and techniques.</li>
                            <li><strong>Organizer</strong>:  Boston HTML5 meetup, TVnext Summit / Hackathon</li>
                            <li><strong>Video</strong>: After Effects, Final Cut Studio, Camtasia Studio</li>
                            <li><strong>Audio</strong>: Logic Pro Studio, Sound Booth, Pro Tools</li>
                            <li><strong>Business</strong>: Microsoft Office, Windows, OSX,</li>
                            <li><strong>User Testing</strong>: A/B and multi-variant testing, usability testing, focus groups, in product messaging and surveying, email tracking and market tracking through web analytics</li>
                            <li><strong>SEO/SEM</strong>: Omniture Site Catalyst, Omniture Test & Target, Google Analytics, Google Website Optimizer</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section class="clients">
                <div class="block one-col">
                    <h3>Clients</h3>
                    <p>Over the years I have had the opportunity to work with some great companies. Here are just some of the companies I have been fortunate enough to work with.</p>
                    <ul class="client-list">

                        <li class="wow fadeInUp"><img src="images/logos/grey-autodesk.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".25s"><img src="images/logos/grey-bank-of-america.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".50s"><img src="images/logos/grey-bd.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".75s"><img src="images/logos/grey-bentley.png" alt="" /></li>
                        <li class="wow fadeInUp"><img src="images/logos/grey-blackstone-group.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".25s"><img src="images/logos/grey-cadillac.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".50s"><img src="images/logos/grey-chilis.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".75s"><img src="images/logos/grey-dunkin-donuts.png" alt="" /></li>
                        <li class="wow fadeInUp"><img src="images/logos/grey-emc.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".25s"><img src="images/logos/grey-home-goods.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".50s"><img src="images/logos/grey-lg.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".75s"><img src="images/logos/grey-marshalls.png" alt="" /></li>
                        <li class="wow fadeInUp"><img src="images/logos/grey-merrell.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".25s"><img src="images/logos/grey-mlb.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".50s"><img src="images/logos/grey-necco.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".75s"><img src="images/logos/grey-novartis.png" alt="" /></li>
                        <li class="wow fadeInUp"><img src="images/logos/grey-porsche.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".25s"><img src="images/logos/grey-pur.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".50s"><img src="images/logos/grey-tasigna.png" alt="" /></li>
                        <li class="wow fadeInUp" data-wow-delay=".75s"><img src="images/logos/grey-tjmaxx.png" alt="" /></li>
                    </ul>
                </div>
            </section>
        </main>
    )
}

export default About


