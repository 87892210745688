import React from 'react'
import Back from './../../components/Back';
import { MeshHead } from './../../components/MeshHead';
import './aevo.scss'
const Aevo = () => {
    return (
        <div>
            <Back back company="The Advocator Group" project="Aevo" location="work" />
            <div className="wd-hero" >
                <MeshHead color2="#243570" color1="#18161b"  opacity='0.5'/>
                <div className="outer-container">
                    <img className="clark-hero" src="/images/aevo/aevo-hero.png" alt="" />
                </div>
            </div>
            <main className="work-detail">
                <section>
                    <div className="block one-col">
                        <a className="website" href="http://aevoservices.com">Visit Website</a>
                        <h1>
                            <span>The Advocator Group</span>
                            Aevo
				<span>Wordpress Website</span>
                        </h1>
                        <div className="two-col-text">
                            <p>When the Advocator Group came to me they had a pretty clear idea of what they wanted in a site in terms of content but were pretty open on what to do with the website for Aevo. For them it all started with the name "Aevo".
					</p><p>Aevo is Latin for “Lifetime” which is the type of relationship we want to have with our clients. Our goal is to provide continued guidance to our clients through the changes they may encounter across their lifetime so that they may thrive and have peace-of-mind through life’s transitions.</p>
                            <p>They wanted the site to be accessible for all ages, warm, inviting and easy to navigate even on the users phone. With the success of there parent company they already has systems in place for handling customer help and crm. These were key areas that also needed to be addressed in the design.</p>

                        </div>
                        <h2>Homepage</h2>
                        <img src="/images/aevo/aevo-home.jpg" alt="" />
                        <h2>Our People</h2>
                        <img src="/images/aevo/aevo-our-people.jpg" alt="" />
                    </div>
                </section>
                <section>

                    <div className="block two-col">
                        <section>
                            <h2>Technology</h2>
                            <ul className="technology-list">
                                <li>Responsive Design</li>
                                <li>Word Press Custom Theme</li>
                                <li>Chat Engage API</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                            </ul>
                        </section>

                    </div>
                </section>
            </main>
        </div>
    )
}

export default Aevo
