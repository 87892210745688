import React from 'react'
import './footer.scss'
const Footer = () => {
    return (
        <footer class="main-footer">
	    <nav><h4>Find me on:</h4>
		    <ul>
			    <li><a href="http://www.linkedin.com/in/jeffboulay/" class="li"><img src="/images/icons/linkedin.svg" alt=""/></a></li>
			    <li><a href="https://twitter.com/jeffboulay" class="tw"><img src="/images/icons/twitter.svg" alt=""/></a></li>
			    <li><a href="https://www.facebook.com/jeffboulay" class="fb"><img src="/images/icons/facebook.svg" alt=""/></a></li>
			    <li><a href="http://www.meetup.com/html5boston/" class="mu"><img src="/images/icons/meetup.svg" alt=""/></a></li>
			    <li><a href="https://github.com/jeffboulay" class="gh"><img src="/images/icons/github.svg" alt=""/></a></li>
		    </ul>
	    </nav>
    </footer>
    )
}

export default Footer
