import React from 'react'
import Back from './../../components/Back';

const AllAboutYou = () => {
    return (
        <div>
            <Back project="All About You" location="lab"/>

            <div className="wd-hero">
                <div className="outer-container">
                    <h1>
                        <span>Hill Holliday</span>
                        All About You
			<span>Hiperwall Interactive Experience</span>
                    </h1>
                </div>
            </div>
            <main className="work-detail redtsny">
                <section>
                    <div className="block one-col">

                        <div className="two-col-text wow slideInUp" >
                            <p>Many of our digital projects aggregate external social trends. In April’s Beacon project, we’ve turned the spotlight inward with an application we call All About You. We’ve combined iBeacon technology, fitness metrics from FitBit, and details from the Facebook Graph to generate a personal status board.</p>
                            <p>As a user approaches the board, their phone recognizes they are nearby using iBeacon. A signal is sent to the display to present how many steps they’ve taken for the day, how long until their next birthday, where they live, went to school, and more.</p>
                            <p>When a user isn’t logged into the app, we show general interest headlines, weather, when the next train is arriving, and other trending data from around the web.</p>
                        </div>
                        <div fit-vids className="fit-vids wow slideInUp">
                            <iframe title="Walk up"src="//player.vimeo.com/video/120987872" width="1200" height="675" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </div>
                    </div>

                </section>

                <section>
                    <section className="video wow slideInUp">
                        <div className="block one-col">
                            <h2>Karen Kaplen sings happy birthday to you</h2>
                            <div fit-vids className="fit-vids">
                                <iframe title="happy birthday" src="//player.vimeo.com/video/120988470" width="1200" height="675" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                            </div>
                        </div>
                    </section>
                </section>

                <section className="wow slideInUp">
                    <div className="block two-col">
                        <section>
                            <h2>Technology</h2>
                            <ul className="technology-list">
                                <li>HTML5 Video W/Transparancy</li>
                                <li>Canvas</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>Backbone</li>
                                <li>iOS</li>
                                <li>iBeacon</li>
                                <li>HiperWall</li>
                                <li>Facebook API</li>
                                <li>Fitbit API</li>
                                <li>MBTA Train Schedule API</li>
                                <li>Rotten Tomato Movie API</li>
                                <li>Yahoo Weather API</li>
                            </ul>
                        </section>
                    </div>
                </section>

            </main>
        </div>
    )
}

export default AllAboutYou
