import React from 'react'
import Back from './../../components/Back';
import './tvnext.scss'
const TVNext = () => {
    return (
        <div>
            <Back company="Hill Holliday" project="TVNext Summite"  location="work" />
            <div className="wd-hero tvnext-hero">
                <mesh-head color1="#000"></mesh-head>
                <div className="outer-container">
                    <img className="t-hero" src="/images/tvnext/tvnext-hero.png" alt="" />
                </div>
            </div>

            <main className="work-detail">
                <section>
                    <div className="block one-col">
                        <a className="website" href="http://tvnextsummit.com/">Visit Website</a>
                        <h1>
                            <span>Hill Holliday</span>
                            TVNext Summit
				<span>Live Interactive Back Channel / Website</span>
                        </h1>
                        <div className="two-col-text wow fadeInUp">
                            <p>Now in its fourth year, Hill Holliday’s TVNext℠ Summit explores the future of television with a focus on modern audience behavior, tech advances, programming innovation, and advertising implications. TVNext has a history of out doing itself every year and this year didn't disappoint. The stage consisted of one main screen and two side (back channel) screens. The back channel screens presented an interactive real-time experience including user tweets, images, intactive polls, and live Q&A. The audience both live and remote were able to interact with the speakers and panelist on stage in real-time through a full responsive second screen experience. They were also able to vote for the 2014 TVNext Hack Challenge category in the MIT Hackathon. </p>
                            <p>A custom website was built to increase excitement for the event with a large 3D train station style count down clock. The website showcased the speakers, topics, sponsors and schedule.</p>
                        </div>
                    </div>
                </section>

                <section className="featured">
                    <div className="pattern"></div>
                    <div className="block one-col">
                        <div className="content  wow fadeInLeft">
                            <h3>On Stage Display</h3>
                            <p>Using HTML5 web sockets and twitter's streaming API the audience was able to interact with speakers &amp; each other.</p>
                        </div>
                        <img className="image wow fadeIn" src="/images/tvnext/tweets.jpg" alt="" />
                    </div>
                </section>
                <section className="gallery-list">
                    <div className="block one-col">
                        <ul>
                            <li>
                                <div className="caption wow fadeInLeft">
                                    <h3>Second Screen Experience</h3>
                                    <p>With a custom responsive real-time experience the audience was able to interact no mater what device they had with them. Live polls increased user engagement and allowed people that were live streaming the event to participate.</p>
                                </div>
                                <img className="wow fadeInRight" src="/images/tvnext/tvnext-backchannel-responsive.png" alt="" />
                            </li>
                        </ul>

                    </div>
                </section>
                <section>
                    <div className="block one-col">
                        <img className="wow fadeInUp" src="/images/tvnext/tvnext-site.png" alt="" />
                        <h2>Website</h2>
                        <div className="two-col-text wow fadeInUp">
                            <p>The event website played a key roll in attracting people to the event. It was also fully responsive and managed by a custom Codeigniter CMS that allowed Hill Holliday to make updates to all the major sections of the site. Attendees were able to register for the event and see a full schedule of the event, and sponsors were prominently displayed lending credibility to the event.</p>
                            <p>During the event their was a  Live Stream that took prominence on the site as well as a link to the second screen experience.</p>
                        </div>
                    </div>
                </section>
                <section >
                    <div className="block two-col">
                        <div className="wow slideInLeft">
                            <h2>Technology</h2>
                            <ul className="technology-list">
                                <li>Responsive Design</li>
                                <li>Twitter Streaming API</li>
                                <li>Real-time Polling</li>
                                <li>Web Socket / Pusher</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>AngularJS</li>
                                <li>D3JS</li>
                                <li>GruntJS</li>
                                <li>Yeoman</li>
                                <li>Laravel PHP</li>
                            </ul>
                        </div>
                        <div className="video wow slideInRight">
                            <h2>Watch All of TVNext</h2>
                            <iframe title="tvnext" width="450" height="275" src="//www.youtube.com/embed/videoseries?list=PL_gnlGlwKoignv-c6yZE6YetyBhWyOf6w" frameBorder="0" allowFullscreen></iframe>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default TVNext
