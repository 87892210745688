import React from 'react'
import { MeshHead } from './../../components/MeshHead';
import Back from './../../components/Back';
import './edge.scss'
const Edge = () => {
    return (
        <div>
            <Back back project="Edge" location="work" />
            <div className="wd-hero" style={{backgroundColor:'#4884c0'}}>
                <MeshHead color2="#4884c0" color1="#152738"></MeshHead>
                <div className="outer-container">
                    <img className="edge-hero" src="/images/edge/edge-hero.png" alt="" />
                </div>
            </div>
            <main className="work-detail">
                <section>
                    <div className="block one-col">
                        <h1>
                            <span>Hill Holliday</span>
                            Edge
				<span>Internal Cultivation Tool</span>
                        </h1>
                        <div className="two-col-text"><p>At Hill Holliday, we pitch a lot of new business. And for each new pitch the new business team asks the agency to volunteer their experience with the prospective client and its vertical market. When the pitch is over, that invaluable relationship information remains locked up in email, where it can’t easily be accessed in the future. We thought there had to be a better way.</p>
                            <p>What if we could capture all employee contact information and experience and make it searchable by prospective company name, individual name, and vertical market? Wouldn’t that give our new business team an edge? For our August Beacon project, we decided to test that theory to find out. We built a tool called Edge. It encourages employees to volunteer their social network connections and past work experience to help drive new business opportunities.</p>
                            <p>Upon signup, each individual is asked to connect their LinkedIn account and Twitter profile, as well as answer several questions about themselves.</p>
                            <p>The extensive use of the LinkedIn API allowed us to build a robust database of company, institution and vertical connections to which end all contributing users are able to search for potential leads and opportunities. The system is configured to update on a regular basis to always ensure that employee information and connections are up-to-date.</p>
                        </div>
                        <img src="/images/edge/interface.jpg" alt="" />
                    </div>
                </section>
                <section>
                    <div className="block two-col">
                        <section>
                            <h2>Technology</h2>
                            <ul className="technology-list">
                                <li>Responsive Design</li>
                                <li>LinkedIn API</li>
                                <li>Twitter API</li>
                                <li>LDAP Integration</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>AngularJS</li>
                                <li>GruntJS</li>
                                <li>Yeoman</li>
                                <li>Laravel PHP</li>
                            </ul>
                        </section>

                    </div>
                </section>
            </main>
        </div>
    )
}

export default Edge
