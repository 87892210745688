import React from 'react'
import Back from './../../components/Back';

const Pur = () => {
    return (
        <div>
            <Back project="Water Intake" location="lab"></Back>
            <div className="wd-hero">
                <mesh-head color1="#3a5087" color2="#3a5087" color3="#000"></mesh-head>
                <div className="outer-container">
                    <img className="front" src="../../../images/pur/pur-hero.png" alt="" />

                </div>
            </div>
            <main className="work-detail redtsny">
                <section>
                    <div className="block one-col">
                        <h1>
                            <span>Hill Holliday</span>
                            Water Intake
			<span>Mobile app / Fitbit Integration</span>

                        </h1>
                        <div className="two-col-text">

				<p>We attached battery-powered, Bluetooth LE, orientation-aware sensors to PUR pitchers and tap mounts. We measured the water used by multiplying the flow rate for each of the filters by the amount of time the filter is engaged, as determined by specified changes to the sensors’ physical orientation. We then captured the data transmitted by the sensors with a custom mobile app that connects to the Internet and shares the sensors' measurements.
				The end result is the PURSmart system. It tracks water consumption effortlessly and encourages users to meet their consumption targets through FitBit and social-app integration.</p>
                        </div>

                    </div>

                </section>

                <section>
                    <section className="video" >
                        <div className="pattern"></div>
                        <div className="block one-col">
                            <h2 >Testing out Fitbit Integration</h2>
                            <div fit-vids className="fit-vids">
                                <iframe title="pur" src="//player.vimeo.com/video/120987869" width="1200" height="675" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                            </div>
                        </div>
                    </section>
                </section>
                <section>
                    <div className="block two-col">
                        <section>
                            <h2>Technology</h2>
                            <ul className="technology-list">
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>BackboneJS</li>
                                <li>Canvas / Data Visualization</li>
                                <li>Fitbit / Fitbit API</li>
                            </ul>
                        </section>
                    </div>
                </section>

            </main>
        </div>
    )
}

export default Pur
