import React from 'react'

import './lab.scss';
import { Link } from 'react-router-dom';
import { MeshHead } from './../components/MeshHead';
const Lab = () => {
    return (
        <main className="experiments">
            <div className="callout">
                <MeshHead color1="#000000" color2="#cb2829" color3="#ffffff"></MeshHead>
                <h1>Lab</h1></div>
            <section className="experiment-list">
                <div className="block one-col">

                    <p>Playground for stuff that is a work in progress or are otherwise interested in. </p>
                    <ul>

                        <li><Link to="/lab/all-about-you">
                            <div className="caption wow slideInRight">

                                <h3>All About You</h3>
                                <p>iBeacons, Facebook API, Rotten Tomatos API, MBTA API, and even a guest appearance from Karen Kaplen herself.</p>
                            </div>
                            
                                <img src="images/experiments/all-about-you.jpg" alt="" className="image-container wow slideInLeft"/>
                            
                        </Link>
                        </li>
                        <li>
                            <Link to="/lab/dd-drivethru">
                                <div className="caption wow slideInRight">

                                    <h3>Dunkin In-car Order App</h3>
                                    <p>GM motors released an SDK for developing apps for Cue in-car entertainment console. We saw this as an opportunity to create synergy with our clients and also empower users to order directly from thier car.</p>
                                </div>
                                <img className="image-container wow slideInLeft" src="images/experiments/dd-drivethru.jpg" alt="" />
                            </Link>
                        </li>
                        <li>
                            <Link to="/lab/oculusriff">
                                <div className="caption wow slideInLeft">

                                    <h3>Oculus Riff</h3>
                                    <p>You’ve been invited to play with your favorite band, onstage tonight, at Madison Square Garden, in front of 18,000 screaming fans. Dreaming? Sweepstakes prize? Reality. Well, virtual reality, but when you grab your guitar, put on the Oculus Rift and play along, you’ll just about believe you’re there.</p>

                                </div>
                                <img className="image-container wow slideInRight" src="images/experiments/oculus-riff.jpg" alt=""/>
                            </Link>
                        </li>
                        <li><Link to="/lab/ibeacon">
                            <div className="caption wow slideInRight">

                                <h3>Apple iBeacon Data Visualization</h3>
                                <p>Came up with some sample data visualizations for the type of data that we could capture from iBeacons in a department store</p>
                            </div>
                            <img src="images/experiments/tjx-datavis.jpg" alt="" className="image-container wow slideInLeft" />
                        </Link>
                        </li>
                        <li><Link to="/lab/pur">
                            <div className="caption">
                                <h3>Pur Intake</h3>
                                <p>Using a Texas Instruments accelerometer we set out to test making a way to track water consumption for a person. We also tied this into the FitBit API to track the consumption with the FitBit Dashboard </p>
                            </div>
                            <img className="image-container wow slideInRight" src="images/experiments/pur-intake.jpg" alt="" />
                        </Link>
                        </li>
                        <li>
                            <Link to="/lab/tweet-shuffle">
                                <div className="caption wow slideInLeft">

                                    <h3>Tweet Shuffle Data Visualization</h3>
                                    <p>Hill Holliday's Hiperwall needed a data visualization to show off what could be done with this.</p>
                                </div>
                                <img className="image-container wow slideInLeft" src="images/experiments/twitter-datavis.jpg" alt="" />
		
                            </Link>
                            </li>
                    </ul>
                </div>
            </section>
        </main> 
    )
}

export default Lab
