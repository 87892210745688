import React, { Component } from 'react'

export class InterestSlider extends Component {
    state={
        currentIndex:0,
        likes:[
            {'label':'to make stuff'},
            {'label':'to get outdoors'},
            {'label':'to break stuff'},
            {'label':'to design'},
	        {'label':'to speak'},
	        {'label':'to program'},
	        {'label':'to visualize data'},
	        {'label':'to bike'},
	        {'label':'to run'},
	        {'label':'to swim'},
	        {'label':'to hike'},
	        {'label':'my family'},
	        {'label':'my friends'},
	        {'label':'chocolate'},
	        {'label':'good BBQ'},
	        {'label':'to drum'}
        ]
    }
    goToNext = ()=>{
        this.setState(prevState => ({
            currentIndex: prevState.currentIndex++
        }))
    }

    componentDidMount() {
        const intervalId = setInterval(this.timer, 2000);
        this.setState({intervalId: intervalId});
    }
    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }

    timer = () => {
        this.setState(prevState => ({
            currentIndex: prevState.currentIndex === prevState.likes.length -1 ? 0: prevState.currentIndex+1
        }))
    }
    render() {
        return (
            <ul>
                {this.state.likes.map((like, index) => (
                    <li key = {index} className={this.state.currentIndex === index ? 'show' : 'hide'}>{like.label}</li>
                ))}
            </ul>
        )
    }
}

export default InterestSlider
