import React from 'react'
import './work.scss';
import './projectDetails.scss'
import { Link } from 'react-router-dom';
import { MeshHead } from './../components/MeshHead';

const Work = () => {
    return (
        <main className="work">
            <div className="callout">
                <MeshHead color1="#000000" color2="#cb2829" color3="#ffffff"></MeshHead>
                <h1>Work</h1>
            </div>
            <section>
                <div className="block one-col">
                    <ul className="diamonds">
                        <li>
                            <Link to="/work/ddsharkweek" className="diamond">
                                <div className="content">
                                    <img src="images/logos/white/dunkin.png" alt="" />
                                    <h2>SharkWeek</h2>
                                </div>
                            </Link>
                            <div className="wrapper">
                                <img src="images/work/ddsharkweek.jpg" alt="" />
                            </div>
                        </li>
                        <li>
                            <Link to="/work/rise" className="diamond">
                                <div className="content">
                                    <img src="images/logos/white/libertymutual.png" alt="" />
                                    <h2>Rise</h2>
                                </div>
                            </Link>
                            <div className="wrapper">
                                <img src="images/work/lm-rise.jpg" alt="" />
                            </div>
                        </li>
                        <li>
                            <Link to="/work/tvnext" className="diamond">
                                <div className="content">
                                    <img src="images/logos/white/tvnext.png" alt="" />
                                    <h2>TVNext Summit</h2>

                                </div>
                            </Link>
                            <div className="wrapper">
                                <img src="images/work/tvnext.jpg" alt="" />
                            </div>
                        </li>
                        <li>
                            <Link to="/work/red-tsnyc" className="diamond">
                                <div className="content">
                                    <img src="images/logos/white/red.png" alt="" />
                                    <h2>(RED) Billboard</h2>
                                </div>
                            </Link>
                            <div className="wrapper">
                                <img src="images/work/red-billboard.jpg" alt="" />
                            </div>
                        </li>
                        <li>
                            <Link to="/work/inspirl" className="diamond">
                                <div className="content">
                                    <img src="images/logos/white/inspirl.png" alt="" />
                                    <h2>inspIRL</h2>

                                </div>
                            </Link>
                            <div className="wrapper">
                                <img src="images/work/inspirl.jpg" alt="" />
                            </div>
                        </li>
                        <li>
                            <Link to="/work/edge" className="diamond">
                                <div className="content">
                                    <h2>Edge</h2>
                                </div>
                            </Link>
                            <div className="wrapper">
                                <img src="images/work/edge.jpg" alt="" />
                            </div>
                        </li>

		<li>
                            <Link to="/work/tasigna" className="diamond">
                                <div className="content">
                                    <img src="images/logos/white/tasigna.png" alt="" />
                                    <h2>Best Shot</h2>

                                </div>
                            </Link>
                            <div className="wrapper">
                                <img src="images/work/tasigna.jpg" alt="" />
                            </div>
                        </li>
                        <li>
                            <Link to="/work/clark" className="diamond">
                                <div className="content">
                                    <img src="images/logos/white/clark.png" alt="" />
                                    <h2>Man Test</h2>
                                </div>
                            </Link>
                            <div className="wrapper">
                                <img src="images/work/clark.jpg" alt="" />
                            </div>
                        </li>
                        <li>
                            <Link to="/work/aevo" className="diamond">
                                <div className="content">
                                    <img src="images/logos/white/aevo.png" alt="" />
                                    <h2>Website</h2>
                                </div>
                            </Link>
                            <div className="wrapper">
                                <img src="images/work/aevo.jpg" alt="" />
                            </div>
                        </li>
                        <li>
                            <Link to="/work/adeo" className="diamond">
                                <div className="content">
                                    <img src="images/logos/white/adeo.png" alt="" />
                                    <h2>Website</h2>
                                    Adeo
                
				</div>
                            </Link>
                            <div className="wrapper">
                                <img src="images/work/adeo.jpg" alt="" />
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        </main>

    )
}

export default Work
