import React from "react";
import { Switch, Route } from "react-router-dom";


export const switchRoutes = routes => {
    return (
        <Switch>
            {routes.map((prop, key) => (
                <Route
                    path={prop.path}
                    component={prop.component}
                    exact={prop.exact}
                    key={key}
                />
            ))}
        </Switch>
    );
};

