import React from 'react'
import './contact.scss';
const Contact = () => {
    return (
        <main class="contact">
            <div class="callout">
                <mesh-head color1="#000000" color2="#cb2829" color3="#ffffff"></mesh-head>
                <h1>contact</h1>
                </div>
            <section>
                <div class="block two-col">
                    <div>
                        <h1>Hello</h1>
                        <p>Thanks for visiting my site. I would love to connect with you. Feel free to use the form to the right or contact me directly on any of the social channels in the footer.</p>
                    </div>
                    <div>
                        <form action="">
                            <p>
                                <label for="email">Your Email:</label><input name="email" id="email" type="email" />
                            </p>
                            <p>
                                <label for="subject">Subject:</label><input name="subject" id="subject" type="text" />
                            </p>
                            <p>
                                <label for="message">Message:</label><textarea name="message" id="" cols="30" rows="10"></textarea>
                            </p>
                            <button class="submit-button">Send Message</button>
                        </form>
                    </div>

                </div>
            </section>
        </main>
    )
}

export default Contact
