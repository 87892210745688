import React from 'react'
import Back from './../../components/Back';
import { MeshHead } from './../../components/MeshHead';
import './inspirl.scss'
const Inspirl = () => {
    return (
        <div>
            <Back back project="InspIRL" location="work" />
            <div className="wd-hero" style={{backgroundColor: '#fa8a55'}}>
                <MeshHead color2="#eb6325" color1="#371709"></MeshHead>
                <div className="outer-container">
                    <div className="hero"><img src="/images/inspirl/inspirl-hero.png" alt="" /></div>

                </div>
            </div>

            <main className="work-detail inspirl">
                <section>
                    <div className="block one-col">
                        <h1>
                            <span>Hill Holliday</span>
                            InspIRL
				<span>Mobile HTML5 App / Web Analytic Dashboard</span>
                        </h1>
                        <div className="two-col-text">
                            <p>When our Chief Strategy Officer, Graham Ritchie, announced to our brand planning team that he wanted people to spend more time outside the office, we decided to build a tool to drive this behavior. Graham’s philosophy (with a hat tip to John LeCarre) is that, for a planner, whose job it is to inspire creative people, “a desk is a dangerous place from which to view the world.” InspIRL, or Inspiration In Real Life, encourages, tracks, and rewards our planners for their brain fueling jaunts out of the office. Think of it as Foursquare for creative inspiration.</p>
                        </div>
                    </div>
                </section>
                <section className="video">
                    <h2>View Case Study</h2>
                    <div className="pattern"></div>
                    <div className="block one-col">
                        <div fit-vids className="fit-vids">
                            <iframe title="Inspirl" src="//player.vimeo.com/video/120988611" width="1200" height="675" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </div>
                    </div>
                </section>
                <section className="gallery">
                    <div className="block one-col">
                        <h2>App Experience</h2>
                        <ul className="four-col">
                            <li className=" wow fadeInUp"><img src="/images/inspirl/app/splash.jpg" alt="" /></li>
                            <li className=" wow fadeInUp" data-wow-delay=".25s"><img src="/images/inspirl/app/leaderboard.jpg" alt="" /></li>
                            <li className=" wow fadeInUp" data-wow-delay=".5s"><img src="/images/inspirl/app/challenges.jpg" alt="" /></li>
                            <li className=" wow fadeInUp" data-wow-delay=".75s"><img src="/images/inspirl/app/checkin.jpg" alt="" /></li>
                        </ul>
                        <h2>Dashboard Experience</h2>
                        <img src="images/inspirl/dashboard.jpg" alt="" />
                    </div>
                </section>

                <section className="tech">
                    <div className="block two-col">
                        <section >
                            <h2>Technology</h2>
                            <ul className="technology-list">
                                <li>Responsive Design</li>
                                <li>Google Custom Map API</li>
                                <li>Google Places API</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>BackboneJS</li>
                                <li>RequireJS</li>
                                <li>D3JS</li>
                                <li>GruntJS</li>
                                <li>Yeoman</li>
                                <li>Laravel PHP</li>
                            </ul>
                        </section>
                        <div className="results">
                            <h2>The Results</h2>
                            <p>Over the course of the year Hill's brand planners were able to break free from there desk and experience the world around them. Inspirl is like FourSquare for creativity. </p>
                            <p>This behavior has become ingrained into Hill Holliday's culture and has helped spark countless ideas. Planners are looking forward to what new challenges they can accomplish.</p>

                        </div>
                    </div>
                </section>

            </main>
        </div>
    )
}

export default Inspirl
