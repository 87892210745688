import React, { Component, Fragment } from "react";
import './App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import appRoutes from './appRoutes';
import { switchRoutes } from './helpers';

class App extends Component {
  render() {
    return (
      <Fragment>
        <Header routes={appRoutes} />
        {switchRoutes(appRoutes)}
        <Footer />
      </Fragment>
    );
  }
}

export default App;

