import React from 'react'
import { MeshHead } from './../../components/MeshHead';
import Back from './../../components/Back';
import './rise.scss'
const Rise = () => {
    return (
        <div>
            <Back company="Liberty Mutual" project="Rise"  location="work" />

            <div class="wd-hero rise" style={{backgroundImage: `url('/images/rise/bg-header-large.jpg')`}}>
                <MeshHead opacity=".3 "></MeshHead>
                <div class="outer-container">
                    <img class="rise-hero" src="/images/rise/rise-hero.png" alt="" />
                    <img class="rise-logo" src="/images/rise/bg-rise.png" alt="" />
                </div>
            </div>
            <main class="work-detail rise">
                <section>
                    <div class="block one-col ">
                        <a class="website" href="http://rise-athletes.hhcctech.com/">Visit Website</a>
                        <h1>
                            <span>Liberty Mutual</span>
                            Rise / Comeback
				<span>Website / Integrated Campaign</span>
                        </h1>
                        <div class="two-col-text wow fadeInUp">
                            <p>While other insurance companies rely on animated critters and celebrity spokespeople to get their message out, we’ve spent the past eight years helping Liberty Mutual become synonymous with personal accountability. Despite being outspent 8-to-1 by competitors, our “Responsibility. What’s your policy?’ campaign has resonated with consumers and LM employees alike, earning countless industry awards and acclaim in both the personal and commercial insurance categories.</p>
                            <p>In addition to iconic broadcast and digital work, we also created “The Responsibility Project,” a branded content platform that’s drawn over 40 million unique visitors to date, as well as the “Liberty Mutual Coach of the Year” program which has been celebrating the role of character and values both on and off the field since 2006.</p>
                        </div>
                        <div fit-vids class="fit-vids wow fadeInUp">
                            <iframe title="Rise" src="//player.vimeo.com/video/120988788" width="1200" height="675" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </div>
                    </div>
                </section>
                <section class="gallery wow fadeInUp">
                    <div class="block one-col">
                        <h2>Gallery</h2>
                        <img class="centered" src="images/rise/screenshots.png" alt="" />
                    </div>
                </section>
                <section class="mountain">

                    <div class="block two-col">

                        <div class="wow fadeInLeft">
                            <h2>Technology</h2>
                            <ul class="technology-list">
                                <li>Parallax</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>Jquery</li>
                                <li>Code Ignighter</li>
                                <li>GruntJS</li>
                                <li>Yeoman</li>
                                <li>Spotify</li>
                                <li>Social Widgets</li>
                            </ul>
                        </div>
                        <div class="press wow fadeInRight">
                            <h2>News & Awards</h2>
                            <ul>
                                <li><a href="http://theadclub.org/maven/hill-holliday-for-liberty-mutual-insurance-integrated/">Best Integrated Campaign Winner</a> Media Maven Award</li>
                                <li><a href="http://www.liaentries.com/winners/?id_medium=25&id_submedium=72&id_category=0&view=details&range=f&page=1&keyword=Hill%20Hollida&medium=&category=&award=&country=&title_brand=&credits=&company_name=&city=&proceed_simple_search=true&proceed_advanced_search=false">Branded Entertainment, Branded Webisodes/Online Content Bronze Award</a> Grand LIA's Awards</li>
                                <li><a href="http://www.socialmediatoday.com/content/best-and-worst-2014-sochi-olympics-marketing-strategy">The Best (and Worst) of 2014 Sochi Olympics Marketing Strategy</a> Social Media Today</li>
                                <li><a href="http://www.adweek.com/videowatch/olympics-help-propel-videos-samsung-chevy-and-liberty-mutual-155846">Olympics Help Propel Videos for Samsung, Chevy and Liberty Mutual</a> Adweek</li>
                                <li>Rich Media Single Entry Silver - Hatch Awards</li>
                            </ul>
                    </div>
                    </div>
                </section>
                <section class="stats">
                    <div class="block one-col">
                        <h2>The Results</h2>
                        <ul>
                            <li class=" wow fadeInUp"><span>1.54M</span> Views +63.8%</li>
                            
				<li class=" wow fadeInUp" data-wow-delay=".5s"><span>885</span> Shares</li>
                            <li class=" wow fadeInUp" data-wow-delay="1s"><span>1.87K</span> Likes</li>
                           
				<li class=" wow fadeInUp" data-wow-delay="1.5s"><span>61.74%</span> Avg. View Time</li>
                        </ul>
                    </div>
                </section>
</main>
        </div>
    )
}

export default Rise
