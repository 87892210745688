import React from 'react'
import Back from './../../components/Back';

const TweetShuffle = () => {
    return (
        <div>
            <Back project="Tweet Shuffle" location="lab"></Back>
            <div className="wd-hero" >
                <mesh-head color1="#000000" color2="#cb2829" color3="#ffffff"></mesh-head>

                <div className="outer-container">
                    <h1>
                        <span>Hill Holliday</span>
                        Tweet Shuffle
			<span>Hiperwall Data Visualization</span>
                    </h1>
                </div>
            </div>
            <main className="work-detail redtsny">
                <section>
                    <div className="block one-col">

                        <div className="two-col-text">
                            <p>Hill Holliday had resently installed a massive interactive video wall. I had overseen the installation of the video wall. It uses fifteen almost seemless 55" displays, a custom podium with control display, eight mac minis, and Hiperwall software to connect it all together. </p>
                            <p>I was also tasked with creating a custom screen saver that would display live twitter data in real-time from key staff members and clients. I wanted to do more then the simple grid layout that they were proposing so I create the tweets on cards and had them randomly shuffle on the screen, highlighting one in the center at a time. I also wanted to showcase the volume of tweets coming in over time so I created a custom live chart that maps volume of tweets over time.</p>
                            <p>The technology for this data visualzation I used python (tornado/django) on the backend and HTML5, CSS3, D3js,jQuery and Backbone for the frontend. I leveraged the Hiperwall software to display multiple video loops along the sides of my data visualization.</p>
                        </div>
                        <img src="images/tweet-shuffle/hiperwall.jpg" alt="" />
                    </div>
                </section>
            </main>
        </div>
    )
}

export default TweetShuffle
