import React from 'react'
import Back from './../../components/Back';
import './redtsny.scss'
import { MeshHead } from './../../components/MeshHead';
const RedTSNY = () => {
    return (
        <div>
            <Back  company="Bank of America / (RED)" project="U2 Invisible" location="work" />

            <div className="wd-hero" style={{backgroundColor:"black"}}>
                <MeshHead color1="#000" opacity='.3'/>
                <div className="outer-container">
                    <img className="hero" src="/images/red/red-hero.png" alt="" />
                </div>
            </div>
            <main className="work-detail redtsny">
                <section>
                    <div className="block one-col">
                        <h1>
                            <span>Bank of America / (RED)</span>
                            U2 Invisible
				<span>Superbowl Integrated Campaign</span>
                        </h1>
                        <div className="two-col-text">
                            <p>For the 2014 Superbowl (Red), Bank of America, and U2 wanted to do something really special, Fight for a cure to AIDS. For this they created an integrated campaign accross multiple channels including Web, Super Bowl commercial, print and two interactive digital displays right in New York City's Times Square. This big marketing push was to promote a free song that U2 released to the iTunes Store called "Invisible". For every download of the song one dollar would be donated to help fight against AIDS. It was a huge success and the song was downloaded over 1 million time in one hour. The first ever Facebook ad with a download button  </p>
                        </div>
                    </div>
                </section>

                <section>
                    <section className="video">
                        <div className="pattern"></div>
                        <div className="block one-col">
                            <h3>View the Case Study</h3>
                            <div fit-vids className="fit-vids">
                                <iframe title="RED" src="//player.vimeo.com/video/120988704" width="1200" height="675" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                            </div>
                        </div>
                    </section>
                </section>
                <section className="gallery-list">
                    <div className="block one-col">
                        <ul>
                            <li>
                                <div className="caption">
                                    <h3>Square Digital Billboard</h3>
                                    <p>The video wraps around the bottom and sides to create a dramatic 3d effect.</p>
                                </div>
                                <img src="/images/red/square.png" alt="" />

                            </li>
                            <li>
                                <div className="caption">
                                    <h3>L-Shaped Digital Billboard</h3>
                                    <p>The unique l-shape allowed this billboard to be seen around both sides of this corner. The real-time countdown counts down to the Super Bowl when the commercial aired and the song was made available. </p>
                                </div>
                                <img src="/images/red/l-shaped.png" alt="" />

                            </li>
                        </ul>
                    </div>
                </section>
                <section className="stats"  style={{backgroundColor:"black"}}>
                    <div className="pattern"></div>
                    <div className="block one-col">

                        <h2  style={{color:"white"}}>In 24 Hour</h2>
                        <ul>
                            <li><span>3.1M</span> Downloads</li>
                            <li><span>112K</span> Campaign Mentions</li>
                            <li><span>107K</span> Tweets</li>
                            <li><span>7.8M</span> Days of Life-Saving AIDS Medication</li>

                        </ul>
                    </div>
                </section>
                <section>
                    <div className="block two-col">
                        <section>
                            <h2>Technology</h2>
                            <ul className="technology-list">
                                <li>Interactive Video</li>
                                <li>PopcornJS</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>JQuery</li>
                            </ul>
                        </section>
                        <section>
                            <h2>Reactions</h2>
                            <img src="/images/red/edge.png" alt="" />
                        </section>
                    </div>
                </section>
                <section className="social"  style={{backgroundColor:"black"}}>
                    <div className="pattern"></div>
                    <div className="block one-col">
                        <h2  style={{color:"white"}}>Social</h2>
                        <ul className="quotes">
                            <li>
                                <p className="quote">Download new U2 song 'Invisible' for FREE now and $1 goes to support @RED's fight against AIDS.  http://smarturl.it/Connect4Red </p>
                                <cite>@jimmyfallon</cite>
                            </li>
                            <li>
                                <p className="quote">Retweet please:
						Download the #NewU2Song 'Invisible' for FREE now and $1 goes to support @RED's fight against AIDS.  http://smarturl.it/Connect4Red </p>
                                <cite>@Oprah</cite>
                            </li>
                            <li>
                                <p className="quote">Download the #NEWU2SONG 'Invisible' for free now and $1 goes to support @RED's fight against AIDS http://Smarturl.it/connect4red  #U2invisible</p>
                                <cite>@TheEllenShow</cite>
                            </li>
                            <li>
                                <p className="quote">You too can help U2 and get a FREE song. $1 goes to support @RED's fight against AIDS. http://www.whosay.com/l/utL7J9a   #U2Invisible. Hanx</p>
                                <cite>@tomhanks</cite>
                            </li>
                            <li>
                                <p className="quote">#NewU2Song 'Invisible' for FREE now and $1 goes to support @RED's fight against AIDS.  http://smarturl.it/Connect4Red  #U2Invisible</p>
                                <cite>@MileyCyrus</cite>
                            </li>

                        </ul>
                    </div>
                </section>

            </main>
        </div>
    )
}

export default RedTSNY
