import React from 'react'
import Back from './../../components/Back';

const OculusRiff = () => {
    return (
        <div>
            <Back project="Oculus Riff" location="lab"></Back>

            <div className="wd-hero">
                <mesh-head color1="#000000" color2="#cb2829" color3="#ffffff"></mesh-head>
                <div className="outer-container">
                    <h1>
                        <span>Hill Holliday</span>
                        Oculus Riff
			<span>3d Video / Oculus Rift VR Experience</span>
                    </h1>
                </div>
            </div>
            <main className="work-detail redtsny">
                <section>
                    <div className="block one-col">

                        <div className="two-col-text">
                            <p>You’ve been invited to play with your favorite band, onstage tonight, at Madison Square Garden, in front of 18,000 screaming fans. Dreaming? Sweepstakes prize? Reality. Well, virtual reality, but when you grab your guitar, put on the Oculus Rift and play along, you’ll just about believe you’re there.</p>

                            <p>The technology team at Hill Holliday shot a 360 video of a band playing a blues jam - minus the lead guitar player. Employees were then invited to come solo along using an Oculus Rift and a Fender Strat. The prototype was something completely unique. By taking the physical world and bringing it into the virtual, the team created an experience that every guitar players dreams about, to play lead guitar right next to their favorite band.</p></div>

                    </div>

                </section>

                <section>
                    <section className="video">
                        <div className="block one-col">
                            <h2 >Hill employees Rock'n with the Oculus</h2>
                            <div fit-vids className="fit-vids">
                                <iframe title="Oculus Riff" src="//player.vimeo.com/video/120987870" width="1200" height="675" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                            </div>
                        </div>
                    </section>
                </section>
                <section className="gallery-list">
                    <div className="block one-col">
                        <ul>
                            <li>
                                <div className="caption">
                                    <h3>Camera Rig Setup</h3>
                                    <p>This is the custom 3D printed rig that we used from 360Heros. It allows us to capture high definition video in 3D and in 360deg. This is necisary for the occulus rift.    </p>
                                </div>
                                <img src="images/oculus-riff/camera.jpg" alt="" />

                            </li>
                            <li>
                                <div className="caption">
                                    <h3>Rocking on Oculus Rift</h3>
                                    <p>Our own VP of Inovation &amp; Technology, Steve Callan tries out the experience.</p>
                                </div>
                                <img src="images/experiments/oculus-riff.jpg" alt="" />

                            </li>
                        </ul>
                    </div>
                </section>
                <section>
                    <div className="block two-col">
                        <section>
                            <h2>Technology</h2>
                            <ul className="technology-list">
                                <li>GoPro Hero Stereoscopic 360 Camera Rig</li>
                                <li>Oculus Rift</li>
                                <li>Kolor View Engine</li>
                            </ul>
                        </section>

                    </div>
                </section>
            </main>
        </div>
    )
}

export default OculusRiff
