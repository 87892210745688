import React from 'react'
import Back from './../../components/Back';

const DDDriveThru = () => {
    return (
        <div>
            <Back back project="DD Drivethru" location="lab"></Back>
            <div className="wd-hero">
                <mesh-head color1="#000000" color2="#cb2829" color3="#ffffff"></mesh-head>
                <div className="outer-container">
                    <h1>
                        <span>Hill Holliday</span>
                        DD Drivethru
			<span>GM Cue In-Dash Display SDK</span>
                    </h1>
                </div>
            </div>
            <main className="work-detail redtsny">
                <section>
                    <div className="block one-col">

                        <div className="two-col-text">
                            <p>Working with GM/Cadillac we wanted to create something great for the launch of the new SDK for the CUE in-dash entertainment system. We wanted to leverage one of Hill Holliday's clients Dunkin Donuts and create a one of a kind in car store locator and purchase system. </p>
                            <p>The SDK runs on a custom java virtual machine and you program using web standard technologies. We used CodeIgniter to create the API that the CUE app consumes. The app was architecture is very similar to Cordova in that the CLI creates a custom app file. Also it provides additional api hooks to core services of the car.</p>
                            <p>All the animations were handled using CSS. For jascript we chose to use Backbonejs because of it's small foot print and ease of integration with that api.</p>
                        </div>
                    </div>

                </section>

                <section>
                    <section className="video">
                        <div className="block one-col">
                            <h2 >In-Dash Emulator Running App</h2>
                            <div fit-vids className="fit-vids">
                                <iframe title="DDDrivethru" src="//player.vimeo.com/video/120987871" width="1200" height="675" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                            </div>
                        </div>
                    </section>
                </section>

            </main>
        </div>
    )
}

export default DDDriveThru
