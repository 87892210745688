import React from 'react'
import Back from './../../components/Back';
import { MeshHead } from './../../components/MeshHead';
import './adeo.scss'
const Adeo = () => {
    return (
        <div>
            <Back company="The Advocator Group" project="Adeo Solutions" location="work"></Back>

            <div className="wd-hero">
                <MeshHead color2="#c14706" color1="#1f0b01" opacity= "0.5" />
                <div className="outer-container">
                    <img className="clark-hero" src="/images/adeo/adeo-hero.png" alt="" />
                </div>
            </div>
            <main className="work-detail">
                <section>
                    <div className="block one-col">
                        <a className="website" href="http://www.adeo-solutions.com/">View Website</a>
                        <h1>
                            <span>The Advocator Group</span>
                            Adeo Solutions
				<span>Wordpress Website</span>
                        </h1>
                        <div className="two-col-text">
                            <p>When the Advocator Group came to me they had a pretty clear idea of what they wanted in a site in terms of content but were pretty open on what to do with the website for Adeo. For them it all started with the name "Adeo".
				</p><p>Adeo is Latin for “undertake” and that guides our work each day – undertaking and overcoming the challenges related to insurance recovery and delivering maximum results.</p>
                            <p>This site was gear more to companies instead of consumers so the wanted the site to be more informational. With the success of there parent company they already has systems in place for handling customer help and crm. These were key areas that also needed to be addressed in the design.</p>
                        </div>
                        <h2>Homepage</h2>
                        <img src="/images/adeo/adeo-homepage.jpg" alt="" />
                        <h2>Our Services</h2>
                        <img src="/images/adeo/adeo-our-services.jpg" alt="" />
                    </div>
                </section>
                <section>

                    <div className="block two-col">
                        <section>
                            <h2>Technology</h2>
                            <ul className="technology-list">
                                <li>Responsive Design</li>
                                <li>Word Press Custom Theme</li>
                                <li>Chat Engage API</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                            </ul>
                        </section>

                    </div>
                </section>
            </main>
        </div>
    )
}

export default Adeo
