import React from 'react'
import Back from './../../components/Back';
import './ddsharkweek.scss'
import { MeshHead } from './../../components/MeshHead';
const DDSharkWeek = () => {
    return (
        <div>
<Back company="Dunkin Donuts" project="DDSharkWeek" location="work"></Back>

            <div className="wd-hero" style={{backgroundImage: `url('/images/ddsharkweek/ddsharkweek2.jpg')`}}>
                <MeshHead opacity =".3"></MeshHead>
                <div className="outer-container">
                    <div className="logo" style={{backgroundImage: `url('/images/ddsharkweek/ddsharkweeklogo.svg')`}}></div>
                    <div className="tv"><img src="/images/ddsharkweek/tv.png" alt="" /></div>
                    <img className="xbox" src="/images/ddsharkweek/xbox-small.png" alt="" />
                </div>
            </div>

            <main className="work-detail ddsharkweek">
                <section >
                    <div className="block one-col">
                        <h1 className="wow fadeInUp">
                            <span>Dunkin Donuts</span>
                            DDSharkWeek
				<span>XBox One Experience</span>
                        </h1>
                        <div className="two-col-text wow fadeInUp">
                            <p>Discovery’s annual “Shark Week” has been the king of summer TV since 1987, and has a cult-like following. Dunkin’ Donuts became a sponsor of Shark Week for the first time this year, and partnered with Discovery on a co-marketing initiative that included on-air product integration during the live Discovery show Shark After Dark, a limited-edition Shark Week donut in DD stores, a temporary logo with a bite taken out of it, and a selfie contest hosted on Twitter, Instagram, and Discovery.com.</p>
                            <p><img src="" alt="" />We wanted to take this even further. We devised and developed a first of its kind companion viewing experience for Shark Week that provided additional shark content to accompany live Discovery programming.</p>
                            <p>The experience was specifically designed to take advantage of a feature on the Xbox One entertainment console that allows users to “snap” two apps (such as live television and a web browser) to their TV screen at the same time. Viewers watching Shark Week through their Xbox One could snap a unique URL, DDSharkWeek.com, to the side of their TV screen to provided supplemental content such has interactive quizzes, realtime polls, live social content, shark trivia, and more.</p>
                            <p>This represented the first time the Xbox One platform had been used by a brand to create an interactive TV experience for live programming like this. For Shark Week fans watching the show on their Xbox One, it offered a second-screen experience on their primary screen – their television. For Dunkin’ Donuts, it represented an opportunity to create sustained engagement with people in their living room, not just for 15 or 30 seconds at a time during commercial breaks, but for minutes or even hours throughout a live national television event.</p>
                        </div>
                        <div fit-vids className="fit-vids wow fadeInUp">
                            <iframe title="DDShark" src="//player.vimeo.com/video/120987868" width="1200" height="675" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </div>
                    </div>

                </section>
                <section >
                    <div className="block two-col">
                        <div className="press wow fadeInLeft">
                            <h2>Press</h2>
                            <ul>
                                <li><a href="http://adage.com/article/digital/marketers-chum-consumers-shark-week/294534/">From Hot Pockets to VW, Chumming Twitter for Consumers During 'Shark Week'</a> AdAge</li>
                                <li><a href="http://www.mediapost.com/publications/article/230956/dunkin-in-multi-element-shark-week-partnership.html">Dunkin' In Multi-Element 'Shark Week' Partnership</a> MediaPost</li>
                                <li><a href="http://brand-innovators.com/blog/2014/08/08/dunkin-takes-a-bite-out-of-summer/">Dunkin’ Takes a Bite Out of Summer</a> Brand Innovators</li>
                                <li><a href="http://news.dunkindonuts.com/Press-Releases/SHARK-WEEK-RUNS-ON-DUNKIN-DUNKIN-DONUTS-AND-DISCOVERY-CHANNEL-PARTNER-FOR-SOCIAL-PROGRAMMING-DI-4ba.aspx">SHARK WEEK RUNS ON DUNKIN’: DUNKIN’ DONUTS AND DISCOVERY CHANNEL PARTNER FOR SOCIAL PROGRAMMING, DIGITAL CONTENT AND SHARK BITE DONUT</a> Dunkin Donuts News</li>
                                <li><a href="http://www.prnewswire.com/news-releases/shark-week-runs-on-dunkin-dunkin-donuts-and-discovery-channel-partner-for-social-programming-digital-content-and-shark-bite-donut-269039941.html">SHARK WEEK Runs On Dunkin': Dunkin' Donuts And Discovery Channel Partner For Social Programming, Digital Content And Shark Bite Donut</a> PRNewswire</li>
                            </ul>
                        </div>
                        <div className="wow fadeInRight">
                            <h2>Technology</h2>
                            <ul className="technology-list">
                                <li>Web Sockets / Pusher</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>BackboneJS</li>
                                <li>RequireJS</li>
                                <li>D3JS</li>
                                <li>GruntJS</li>
                                <li>Yeoman</li>
                                <li>XBox Web App</li>
                                <li>XBox Voice Commands</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="stats">
                    <div className="block one-col">
                        <h2>The Results</h2>
                        <ul>
                            <li className=" wow fadeInUp">
                                <span>3.6m</span>
                                tuned into sharkweek
				</li>

                            <li className=" wow fadeInUp" data-wow-delay=".5s">
                                <span>120k</span>
                                engaged with our content
				</li>
                            <li className=" wow fadeInUp" data-wow-delay="1s">
                                <span>13min</span>
                                average time used
				</li>
                            <li className=" wow fadeInUp" data-wow-delay="1.5s"><span>
                                18k
			</span>
                                opted in for DDPerks info</li>
                        </ul>
                    </div>
                </section>
                <section className="gallery">
                    <div className="block one-col">
                        <h2>The Experience</h2>
                        <ul>
                            <li className=" wow fadeInUp"><img src="/images/ddsharkweek/quiz.jpg" alt="" /><h3>Trivia / Live Polls</h3></li>
                            <li className=" wow fadeInUp" data-wow-delay=".5s"><img src="/images/ddsharkweek/results.jpg" alt="" /><h3>Trivia Results</h3></li>
                            <li className=" wow fadeInUp" data-wow-delay="1s"><h3><img src="/images/ddsharkweek/facts.jpg" alt="" />Shark Facts</h3></li>
                            <li className=" wow fadeInUp"><h3><img src="/images/ddsharkweek/take-a-bite.jpg" alt="" />Bite of the Night</h3></li>
                            <li className=" wow fadeInUp" data-wow-delay=".5s"><h3><img src="/images/ddsharkweek/social.jpg" alt="" />Instagram / Twitter</h3></li>
                            <li className=" wow fadeInUp" data-wow-delay="1s"><h3><img src="/images/ddsharkweek/program.jpg" alt="" />Program Guide</h3></li>

                        </ul>
                    </div>
                </section>
                <section className="wow fadeInUp social">
                    <div className="block one-col">
                        <h2>Social</h2>
                        <ul className="quotes">
                            <li>
                                <p className="quote">#Xbox has a free shark week theme. #DayMade</p>
                                <cite>@drewygorezk</cite>
                            </li>
                            <li>
                                <p className="quote">Heck Ya I'm a great white shark #Sharkweek #xbox</p>
                                <cite>@theRJNator</cite>
                            </li>
                            <li>
                                <p className="quote">The #sharkweek 2nd screen w/xbox is pretty awesome.</p>
                                <cite>@BrianSharks</cite>
                            </li>
                            <li>
                                <p className="quote">Love my sharkatar! #Sharkweek #xbox</p>
                                <cite>@Kategotugun</cite>
                            </li>

                        </ul>
                        <iframe title="DDSharkWeek" className="vine-embed" src="https://vine.co/v/MYVLa96Daq9/embed/postcard" width="320" height="320" frameborder="0"></iframe><script async src="//platform.vine.co/static/scripts/embed.js" charset="utf-8"></script>
                        <blockquote className="twitter-tweet" lang="en"><p>She&#39;s actually a shark. <a href="https://twitter.com/hashtag/DDSharkWeek?src=hash">#DDSharkWeek</a> <a href="http://t.co/X6aTAeEaeY">pic.twitter.com/X6aTAeEaeY</a></p>&mdash; han ♡ (@hannah_neve) <a href="https://twitter.com/hannah_neve/status/500019083392602113">August 14, 2014</a></blockquote>
                        <script async src="//platform.twitter.com/widgets.js" charset="utf-8"></script>
                    </div>
                </section>
                <section>
                    <div className="block one-col">

                    </div>
                </section>
            </main>
        </div>
    )
}

export default DDSharkWeek
