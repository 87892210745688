
import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import appRoutes from './../appRoutes';
import './header.scss';


export class Header extends Component {
    state={
        menu:false
    }
    toggleMenu() {
        this.setState({menu: !this.state.menu})
    }
    render() {
        return (
            <header ng-controller="NavbarCtrl" className="main-header">
                <div className="logo"><a href="/"><img src="images/jb-logo.svg" alt="" /></a></div>
                <div className="nav-button" onClick={() => { this.toggleMenu() }}><button>Foo</button></div>
                <nav className={"main-nav navbar-collapse collapse "+(this.state.menu?'show': 'hide') } id="navbar-main">
                    <ul className="nav navbar-nav">
                        <li><img className="logo" src="images/jb-logo.svg" alt="" /></li>
                        {appRoutes.map((prop, key) => {
                            if (prop.redirect) return null;
                            if (prop.hidden) return null;
                            return (
                                <li key={key} onClick={() => { this.toggleMenu() }}>
                                    <NavLink
                                        to={prop.path}
                                        activeClassName="active"
                                    >
                                        {prop.navbarName}
                                    </NavLink>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </header>
        )
    }
}

export default Header
