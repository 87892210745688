import React from 'react'
import './Home.scss'
import InterestSlider from '../components/InterestSlider'
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <main className="hero debug-on">
            <div className="left">

                <div className="info">
                    <img src="images/jbmain.png" alt="" className="me" />
                    <div className="fourth-hero view-work">
                        <div className="small-diamond dmid">
                            <div>
                                <Link to="/work" className="button-large ">View All Work</Link>
                            </div>
                        </div>
                    </div>
                    <h1>Hello</h1>
                    <div className="skill-list"><h2>I like </h2>

                        <InterestSlider />
                        <Link to="/about">Learn more about me</Link>
                    </div>

                </div>

            </div>
            <div className="parallax">
                <div className="parallax__group first-hero">
                    <Link to="/work/ddsharkweek">
                        <div className="diamond">
                            <div></div>
                        </div>
                    
			<div className=" parallax__layer--right parallax__layer">
                            <div className="small-diamond dright">
                                <div><img src="images/ddsharkweek/ddsharkweeklogo.svg" alt="" /></div>
                            </div>
                        </div>
                        <div className="parallax__layer--left parallax__layer">
                            <div className="small-diamond dleft">
                                <div>
                                    <h1>DDSharkweek</h1>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="parallax__group second-hero">
                    <Link to="/work/inspirl">
                        <div className="diamond">
                            <div></div>
                        </div>
                        <div className=" parallax__layer--right parallax__layer">
                            <div className="small-diamond dright">
                                <div><img src="images/inspirl/inspirl-logo.svg" alt="" /></div>
                            </div>
                        </div>
                        <div className="parallax__layer--left parallax__layer">
                            <div className="small-diamond dleft">
                                <div>
                                    <h1>inspIRL</h1>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="parallax__group third-hero">
                    <Link to="/work/rise">
                        <div className="diamond">
                            <div></div>
                        </div>
                        <div className=" parallax__layer--right parallax__layer">
                            <div className="small-diamond dright">
                                <div><img src="images/logos/white/libertymutual.png" alt="" /></div>
                            </div>
                        </div>
                        <div className="parallax__layer--left parallax__layer">
                            <div className="small-diamond dleft">
                                <div>
                                    <h1>Liberty Mutual Rise</h1>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </main>
    )
}

export default Home
