import React from 'react'
import Back from './../../components/Back';
import { MeshHead } from './../../components/MeshHead';
import './clark.scss'
const Clark = () => {
    return (
        <div>
            <Back company="Necco / Clark Bar" project="Are you Clark enough?" location="work" />
            <div className="wd-hero" style={{backgroundColor:'#cf1b13'}}>
                <MeshHead color2="#cf1b13" color1="#3b0805"></MeshHead>
                <div className="outer-container">
                    <img className="hero" src="/images/clark/clark-hero.png" alt="" />
                </div>
            </div>
            <main className="work-detail">
                <section>
                    <div className="block one-col">
                        <a className="website" href="http://areyouclarkenough.com">View Website</a>
                        <h1>
                            <span>Necco / Clark Bar</span>
                            Are you Clark enough?
				<span>Website / Man Test / Commercial Translator</span>
                        </h1>
                        <div className="two-col-text">
                            <p>While Clark Bars have been around since 1917, their brand awareness had dwindled to near-obscurity in recent years. So we set out to change that. After determining that there’s a huge, untapped male-centric market for this candy, we created an entire campaign called “Are You Clark Enough” that played on men’s collective weakness for watching beautiful women on TV, as well as their incessant need to prove their “manliness” via a “Man Test” web application on AreYouClarkEnough.com.</p>
                        </div>
                    </div>
                </section>
                <section className="video"  style={{backgroundImage: `url('/images/clark/clark-1.jpg')`, backgroundSize:'cover'}}>

                    <div className="block one-col">
                        <h2>View Case Study</h2>
                        <div fit-vids className="fit-vids">
                            <iframe title="clark" src="//player.vimeo.com/video/120988523" width="1200" height="675" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </div>
                    </div>
                </section>
                <section className="gallery-list">
                    <div className="block one-col">
                        <ul>
                            <li>
                                <div className="caption">
                                    <h3>Man Test</h3>
                                    <p>Using the device camera, facebook images, or uploading an image users can test how manly they are. We used the beta face API to get actual facial feature detection to determine the masculinity of the person in the photo.</p>
                                </div>
                                <img src="/images/clark/man-test.jpg" alt="" />

                            </li>
                            <li>
                                <div className="caption">
                                    <h3>Video Translator</h3>
                                    <p>Using YouTube and a custom javascipt users were able to flip a switch and here the original commercials or the translated versions.</p>
                                </div>
                                <img src="/images/clark/video-translator.jpg" alt="" />

                            </li>
                        </ul>
                    </div>
                </section>
                <section>

                    <div className="block two-col">
                        <section>
                            <h2>Technology</h2>
                            <ul className="technology-list">
                                <li>Responsive Design</li>
                                <li>LinkedIn API</li>
                                <li>Twitter API</li>
                                <li>LDAP Integration</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>AngularJS</li>
                                <li>GruntJS</li>
                                <li>Yeoman</li>
                                <li>Laravel PHP</li>
                            </ul>
                        </section>
                    </div>
                </section>
                <section className="stats"  style={{backgroundColor: 'black'}}>
                    <div className="pattern"></div>
                    <div className="block one-col">
                        <h2  style={{color: 'white'}}>In 24 Hour</h2>
                        <ul>
                            <li>prior to the launch clark was sold in 700 store nation wide after it is sold more then 3000 store in the test market alone</li>
                            <li>outselling it's main competitor</li>
                            <li>Sales have spiked 600%</li>
                            <li>Guys have reacted exactly how we expected them too.</li>
                        </ul>
                    </div>
                </section>
                <section>
                    <div className="block one-col">
                        <h2>Press</h2>
                        <ul>
                            <li><a href="http://www.adweek.com/adfreak/beautiful-foreign-women-talk-whole-lot-crap-amusingly-deceptive-candy-ads-153526">Beautiful Foreign Women Talk a Whole Lot of Crap in Amusingly Deceptive Candy Ads Hill Holliday translates</a> AdAge</li>
                            <li><a href="http://www.fabawards.com/fab-entry/are-you-clark-enough/"> (Online Advertising & Integrated)</a> FAB Award</li>
                        </ul>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Clark
