import React from 'react'
import Back from './../../components/Back';

const iBeacon = () => {
    return (
        <div>
            <Back project="iBeacon Data Visualization" location="lab"></Back>

            <div className="wd-hero" >

                <mesh-head color1="#000000" color2="#cb2829" color3="#ffffff"></mesh-head>
                <div className="outer-container">
                    <h1>
                        <span>Hill Holliday</span>
                        iBeacon Data Visualization
			<span>Concept</span>
                    </h1>
                </div>
            </div>
            <main className="work-detail redtsny">
                <section>
                    <div className="block one-col">
                        <div className="two-col-text">
                            <p>Many of our digital projects aggregate external social trends. In April’s Beacon project, we’ve turned the spotlight inward with an application we call All About You. We’ve combined iBeacon technology, fitness metrics from FitBit, and details from the Facebook Graph to generate a personal status board.</p>
                            <p>As a user approaches the board, their phone recognizes they are nearby using iBeacon. A signal is sent to the display to present how many steps they’ve taken for the day, how long until their next birthday, where they live, went to school, and more.</p>
                            <p>When a user isn’t logged into the app, we show general interest headlines, weather, when the next train is arriving, and other trending data from around the web.</p>



                        </div>
                    </div>
                </section>
                <section className="gallery-list">
                    <div className="block one-col">
                        <ul>
                            <li>
                                <div className="caption">
                                    <h3>Node Diagram view</h3>
                                    <p></p>
                                </div>
                                <img src="/images/tjx/node-diagram.jpg" alt="" />
                            </li>
                            <li>
                                <div className="caption">
                                    <h3>Insights</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, beatae dignissimos earum eligendi excepturi porro quia quod repellendus sapiente sunt.</p>
                                </div>
                                <img src="/images/tjx/insights.jpg" alt="" />
                            </li>
                            <li>
                                <div className="caption">
                                    <h3>Heat Map</h3>
                                    <p>Shows customer purchases and time spent in a given department</p>

                                    <h4>Problem</h4>
                                    <p>As a store manager I want to be able to where customers spend there time in my store and how long it takes them to make a purchase in each section. I also what to know what demographic spends time in which section.</p>

                                    <h4>Insights</h4>
                                    <ul>
                                        <li>Highlight areas that has low time-spent and high purchase volume</li>
                                        <li>Point out underutilized or under performing sections of the store</li>
                                        <li>Highlight different patterns during the day</li>
                                    </ul>

					</div>
                            <img src="/images/tjx/heat-map.jpg" alt="" />
				</li>
                        <li>
                            <div className="caption">
                                <h3>Heads Up View</h3>
                                <p>Show multiple graphs on one page representing all departments over a given timespan </p>
                                Insights
                                seeing all the peaks and valleys at the same time highlights Store-wide trends verses department trends
                                Creates a store fingerprint allowing you to compare that store to another store or an aggregate of all stores
        
					</div>
                            <img src="/images/tjx/heads-up-view.jpg" alt="" />
                        </li>
                        <li>
                            <div className="caption">
                                <h3>Compare Arc</h3>
                                <p>Alphabetically sorted order of departments evenly spaced along the x axis. Arcs show user journey through the store. Multiple users can be shown on the graph to find patterns. List can be sorted and flitered to pivot the date and show relevant information.
						</p>
                            </div>
                            <img src="/images/tjx/compare-arc.jpg" alt="" />
                        </li>
                        <li>
                            <div className="caption">
                                <h3>Bar Charts</h3>
                                <p>A series of bar charts for every department. Bar charts can filter on two things at a time one being displayed with height the other being displayed with color variation. A time slider allows the user to select a point in time, a range of time, and playback the interactions. </p>
                            </div>
                            <img src="/images/tjx/bar-chart.jpg" alt="" />
                        </li>
                        <li>
                            <div className="caption">
                                <h3>Arc View</h3>
                                <p>Plots user path in a linear line with x axis representing duration in location and y axis representing amount purchased at location
        
                                    Problem
        
                                    Insights
                                    Show most common paths a user takes throughout the store
                                    Highlight outliers pointing out unusual trends that could shape store for better flow
                                    Correlate  AOV(Average Order Value) to time spent in a department
                                    Highlight how relation of departments can affect AOV
                                    Point out locations of the store that people spend a long time before making a purchase
                                    are they searching for there size
                                    are they experiencing choice paralysis
							Point out crossing patterns ( consumer travels back and forth from one section back to another section multiple times)</p>
                            </div>
                            <img src="/images/tjx/arc-view.jpg" alt="" />
                        </li>
			</ul>
		</div>
	</section>
</main>
        </div>
    )
}

export default iBeacon
